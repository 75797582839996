import tc_be_nl from "./be/terms-conditions-dutch";
import tc_be_en from "./be/terms-conditions-english";
import tc_be_fr from "./be/terms-conditions-french";

import tc_nl_nl from "./nl/terms-conditions-dutch";
import tc_nl_en from "./nl/terms-conditions-english";

import tc_lu_fr from "./lu/terms-conditions-french";
import tc_lu_en from "./lu/terms-conditions-english";

export const TCs = {
  nl: {
    nl: tc_nl_nl,
    en: tc_nl_en,
  },
  be: {
    nl: tc_be_nl,
    en: tc_be_en,
    fr: tc_be_fr,
  },
  lu: {
    en: tc_lu_en,
    fr: tc_lu_fr,
  },
};

export default TCs;
