import React, { useEffect } from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import Footer from "../components/Footer";

import { device } from "../globalStyles";

import { useLangContext } from "../context/langContext";
import { useCountryContext } from "../context/countryContext";

import TCs from "../constants/terms-conditions/terms-conditions";

import Seo from "../components/SEO";
import I18N from "../constants/languages/translations";

const Container = styled.div`
  padding: var(--gutter);
  margin: auto;
  max-width: 872px;
  padding-top: 24px;
  @media (${device.minTablet}) {
    padding-top: 80px;
  }

  & > p {
    margin-bottom: 16px;
    & + h5 {
      margin-top: 32px;
    }
  }

  & > ul {
    list-style: disc;
    li {
      margin-inline-start: 2em;
      padding-inline-start: 1em;
      margin-block-end: 0.8em;
      &:last-of-type {
        margin-bottom: 32px;
      }
    }
  }

  b {
    font-family: "Roboto", sans-serif;
  }
`;

export const TcTitle = styled.h1`
  margin-bottom: 32px;
`;

export const TcSubtitle = styled.h5`
  margin-bottom: 16px;
`;

const TermsConditions = (props) => {
  const { lang } = useLangContext();
  const { country } = useCountryContext();
  const copy = I18N[country][lang].landing;

  useEffect(() => {
    if (typeof window === "undefined") return;
    // If there is both a hash and a query string, the scrollTo function does not work (eg #privacy?lang=fr)ss
    if (props.location.hash) {
      // timeout below is so the langContext has time to catch the ?lang before this function removes it
      setTimeout(
        () =>
          props.navigate(
            props.location.pathname + props.location.hash.split("?")[0],
            { replace: true }
          ),
        0
      );
    }
  }, []);

  return (
    <Layout title={copy.campaign} pageName="terms-and-conditions">
      <Container>{TCs[country][lang]()}</Container>
      <Footer />
    </Layout>
  );
};

export default TermsConditions;

export const Head = () => <Seo />;
