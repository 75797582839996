import React from "react";
import { TcTitle, TcSubtitle } from "../../../pages/terms-conditions";

const TermsConditionsFrench = () => {
  return (
    <>
      <TcTitle>Conditions générales du « KLM Making Memories »</TcTitle>
      <p>
        <i>Aucun achat n’est nécessaire</i>
      </p>
      <TcSubtitle>I. Promotion</TcSubtitle>
      <p>
        Les présentes Conditions générales s'appliquent à la Promotion KLM
        Making Memories (ci-après : la « Promotion »), disponible uniquement sur
        le site internet suivant (le « Site web ») :{" "}
        <a href="http://signuptoplay.klm.lu">http://signuptoplay.klm.lu</a> et
        organisée par Koninklijke Luchtvaart Maatschappij N.V. (également connue
        sous le nom KLM Royal Dutch Airlines), Amsterdamseweg 55, 1182 GP
        Amstelveen, Pays-Bas (« KLM »). La participation à cette Promotion
        implique l'acceptation des présentes conditions générales.
      </p>
      <p>
        La participation à la Promotion et la chance de gagner le Prix se font
        en complétant le jeu « KLM Making Memories », en fournissant les données
        personnelles requises (prénom, nom, adresse e-mail), et en acceptant les
        présentes conditions générales. Toutes les réponses doivent être
        correctes pour avoir une chance de gagner.
      </p>
      <p>
        Le Participant à cette Promotion jouera à un jeu de mémoire et
        sélectionnera toutes les photos qui appartiennent à une certaine
        destination. Les Participants auront la possibilité d'inviter leur(s)
        ami(s) à participer au défi. Chaque Participant éligible pourra avoir
        une chance de gagner le Prix - un billet d'avion KLM aller-retour.
      </p>
      <TcSubtitle>II. Participation</TcSubtitle>
      <ul>
        <li>
          La participation à cette Promotion est volontaire et est limitée à une
          inscription par personne.
        </li>
        <li>
          La participation à la Promotion est gratuite et le Participant doit
          fournir un nom, un prénom et une adresse électronique valides. La
          participation est ouverte à toute personne âgée d'au moins 18 ans et
          résidant au Grand-Duché de Luxembourg (et y habite officiellement en
          tant que résident).
        </li>
        <li>
          Un Participant ne peut participer qu’une fois par nom unique, nom de
          famille, pays et adresse e-mail.
        </li>
        <li>
          L'adresse e-mail communiquée sera considérée comme étant le numéro de
          participation.
        </li>
        <li>
          Les employés d'Air France et de KLM et de ses sous-traitants, ainsi
          que leurs parents au premier degré (enfants), sont exclus de la
          participation à la Promotion.
        </li>
        <li>
          KLM se réserve le droit d'exiger une preuve écrite de la résidence et
          de l'âge du gagnant (18 ans et plus) avant d'attribuer le Prix.
        </li>
        <li>
          KLM se réserve le droit d'exclure des Participants. Les réponses
          incorrectes ou incomplètes ne seront pas acceptées.
        </li>
        <li>
          L'utilisation de données de tiers est strictement interdite. En cas
          d'une telle utilisation, KLM se réserve le droit de disqualifier
          définitivement le Participant. KLM se réserve le droit de retirer
          toute participation.
        </li>
        <li>
          Les Participants recevront la newsletter de KLM s'ils choisissent de
          s’abonner à celle-ci lors de leur Participation. Si un Participant ne
          souhaite plus recevoir la newsletter de KLM, il peut se désabonner en
          cliquant sur le bouton qui se trouve au bas de la newsletter de KLM.
        </li>
      </ul>
      <TcSubtitle>III. Durée</TcSubtitle>
      <p>
        Cette Promotion débute le 28 novembre 2023 à 00h01 et le délai de la
        dernière participation est fixé à 23h59 le 11 décembre 2023 (GMT +1).
      </p>
      <TcSubtitle>IV. Prix</TcSubtitle>
      <p>Le Prix se compose des éléments suivants :</p>
      <ul>
        <li>
          1 x 2 billets KLM aller-retour au départ de Luxembourg, via Amsterdam,
          vers une destination au choix du gagnant au sein du réseau KLM, d'une
          valeur maximale de 750 € par billet (le "<b>Prix</b>").
        </li>
      </ul>
      <p>
        <b>
          Informations relatives au Prix et à la manière dont il sera décerné :
        </b>
      </p>
      <ul>
        <li>
          Après la période de Promotion, le Prix sera attribué à un seul
          gagnant.
        </li>
        <li>
          Le gagnant sera le Participant qui répondra le plus rapidement parmi
          tous les Participants dont les réponses seront toutes correctes et
          ayant saisi une adresse électronique valide.
        </li>
        <li>
          Le gagnant doit commencer et terminer son voyage à l'aéroport de
          Luxembourg (Findel) et les vols doivent être opérés par KLM Royal
          Dutch Airlines.
        </li>
        <li>
          Le gagnant du Prix doit réserver son vol dès que possible, la
          réservation devant être effectuée au moins 6 semaines avant le départ
          et le retour avant le 15 décembre 2024.
        </li>
        <li>
          Un séjour minimum de 3 jours ou une nuit du samedi au dimanche doit
          être inclus dans le voyage.
        </li>
        <li>
          Le gagnant recevra un courrier électronique l'informant qu'il a gagné
          et lui indiquant comment accepter son prix le lundi 18 décembre 2023.
        </li>
        <li>
          Toutes les décisions prises par KLM concernant ce Prix seront
          considérées comme finales ; aucune correspondance ne sera échangée.
        </li>
        <li>
          Les billets sont non remboursables et non flexibles. Toutes les taxes,
          suppléments et frais sont inclus.
        </li>
        <li>
          Les billets sont soumis à disponibilité ; la disponibilité est très
          limitée pendant les périodes de forte affluence (par exemple les
          vacances scolaires ou les événements importants). Les billets sont
          attribués sur une base strictement personnelle et ne sont pas
          transférables. De même, les billets ne peuvent être échangés contre de
          l'argent ou toute autre forme de rémunération.
        </li>
        <li>
          Le gagnant peut indiquer une autre personne qui voyagera avec lui. Une
          fois que le nom de cette personne aura été communiqué à KLM, les
          billets seront émis à titre strictement personnel et ne pourront être
          transférés.
        </li>
        <li>
          Sur les deux billets KLM gagnés, l'un est délivré au gagnant et
          l'autre à un invité du gagnant. Le gagnant et son invité doivent
          voyager ensemble à l'aller et au retour.
        </li>
        <li>Le gagnant ne peut pas utiliser le deuxième billet.</li>
        <li>
          KLM est responsable de toute taxe sur les jeux de hasard. Le gagnant
          des billets est responsable de tous les frais, coûts (de carte de
          crédit), demandes de frais de demande de visa ou pourboires qui ne
          sont pas explicitement inclus dans ce Prix ou associés à celui-ci
          (tels que les repas supplémentaires, les transferts vers et depuis
          l'aéroport, les frais de stationnement, les assurances voyage, etc).
        </li>
        <li>
          En acceptant le Prix, le gagnant reconnaît qu'il accepte celui-ci
          entièrement à ses propres risques et à son entière responsabilité.
        </li>
        <li>
          Le Prix ne peut être échangé contre aucun autre Prix ou service ou
          contre des espèces. Les Prix ne sont pas cessibles. Le Prix n'a aucune
          valeur en espèces, même si la valeur des billets est inférieure à 750
          €.
        </li>
        <li>
          KLM se réserve le droit de modifier le type de Prix au cours de la
          Promotion ou de remplacer le Prix par un Prix différent de valeur
          égale. Cette alternative ne peut en aucun cas donner lieu à un
          remplacement ou à un remboursement.
        </li>
        <li>
          KLM a le droit de demander une vérification écrite du lieu de
          résidence et de l'âge du gagnant potentiel avant d'attribuer le Prix.
          KLM a le droit d'exclure de la participation les inscriptions qui ne
          sont pas conformes aux présentes conditions générales et, par
          conséquent, de ne pas attribuer le Prix.
        </li>
      </ul>
      <TcSubtitle>V. Données personnelles</TcSubtitle>
      <ul>
        <li>
          KLM conservera les informations suivantes fournies par les
          Participants lors de la première étape : prénom, nom, adresse e-mail.
          Le Participant recevra un maximum de 2 e-mails liés à cette Promotion.
          Si le Participant s'inscrit à notre newsletter afin de participer au
          tirage au sort, KLM conservera les informations suivantes fournies par
          les Participants [prénom, nom, adresse e-mail, pays et langue] pour la
          Promotion et à des fins de marketing dans la mesure où les
          Participants ont déjà opté pour cette option. Les Participants
          recevront la newsletter de KLM, s'ils ont choisi de la recevoir au
          cours de leur participation. Si un Participant ne souhaite plus
          recevoir la newsletter de KLM, il peut se désinscrire en cliquant sur
          le bouton situé en bas de celle-ci.
        </li>
        <li>
          KLM s'assurera que la collecte et le traitement de ces informations
          sont conformes aux lois sur la protection de la vie privée.
        </li>
        <li>
          Cette Promotion est soumise à la politique de confidentialité de KLM,
          qui est disponible{" "}
          <a
            href="https://www.klm.lu/fr/information/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            ici
          </a>
          .
        </li>
        <li>
          KLM et / ou ses sous-traitants n'utiliseront pas les données
          personnelles des Participants à d'autres fins commerciales ou pour les
          vendre à un tiers.
        </li>
      </ul>
      <TcSubtitle>VI. Questions et plaintes</TcSubtitle>
      <ul>
        <li>
          Les utilisateurs qui ont des réclamations à formuler concernant la
          Promotion peuvent le faire via les réseaux sociaux de KLM, 24 heures
          sur 24 et 7 jours sur 7.
        </li>
      </ul>
      <TcSubtitle>VII. Divers</TcSubtitle>
      <ul>
        <li>
          Si, pour quelque raison que ce soit, la Promotion ne peut se dérouler
          comme prévu, ou en cas d'infection par des virus informatiques ou des
          bugs, de falsification, d'intervention non autorisée, de fraude,
          d'actions des Participants, de défaillances techniques, ou de toute
          autre cause corrompant ou compromettant l'administration, la sécurité,
          l'équité, l'intégrité ou le bon déroulement de la Promotion, KLM se
          réserve le droit, à sa seule discrétion, de disqualifier toute
          inscription ou tout Participant et/ou d'annuler, de mettre fin, de
          modifier ou de suspendre la Promotion, et, à sa seule discrétion,
          d'attribuer le Prix au deuxième Participant le plus rapide parmi
          toutes les participations éligibles non suspectes valablement reçues
          avant l'action nécessitant une telle modification. KLM n'est pas
          responsable du brouillage ou des pannes de réseau, de matériel
          informatique ou de logiciel de toute nature qui restreignent,
          retardent ou causent la perte des formulaires des Participants ou
          d'autres données.
        </li>
        <li>
          KLM se réserve le droit de modifier les Conditions générales, le Prix,
          les définitions ou d'autres informations, ou d'annuler la Promotion à
          tout moment sans préavis.
        </li>
        <li>
          La responsabilité de KLM est limitée exclusivement à la valeur du Prix
          choisi. En s'inscrivant, les Participants libèrent et acceptent de
          défendre, d'indemniser et de tenir KLM à l'écart de toute réclamation,
          demande, perte et cause d'action pour tout dommage (y compris, sans
          s'y limiter, tous les dommages consécutifs, économiques et
          accidentels), responsabilité, perte, coûts, honoraires (y compris les
          honoraires d'avocat), y compris, sans s'y limiter, l'atteinte à
          l'identité, à la réputation, à l'image d'une personne ou à celle d'un
          tiers (y compris le décès) ou à des biens personnels ou immobiliers,
          due en tout ou partie, directement ou indirectement, à l'acceptation,
          la possession ou la participation à la Promotion et qu'elle soit ou
          non causée, en tout ou partie, par les actes, omissions, négligences
          ou fautes de KLM.
        </li>
        <li>
          Cette Promotion est soumise au code de conduite relatif aux jeux de
          hasard Promotionnels. (Loi sur les jeux de hasard Promotionnels)
        </li>
        <li>
          Cette Promotion (y compris les présentes conditions générales) est
          soumise au droit national. En cas de litiges, le tribunal est seul
          compétent pour statuer sur ces litiges, sauf si la loi impérative en
          dispose autrement.
        </li>
      </ul>

      <TcTitle id="privacy">Politique de confidentialité</TcTitle>
      <ul>
        <li>
          Nous enverrons au Participant la newsletter de KLM chaque semaine, qui
          le tiendra informé des tarifs attractifs, des destinations inspirantes
          et des dernières nouveautés. Nous pouvons également envoyer au
          Participant des e-mails à des occasions spécifiques, comme une offre
          spéciale pour son anniversaire, des offres personnalisées pour son
          prochain voyage ou pour vous aider à effectuer sa réservation
          lorsqu’il/elle quitte le jeu avant la fin.
        </li>
        <li>
          Toutes les newsletters sont envoyées par KLM, aucune coordonnée n'est
          partagée avec nos partenaires. Si le Participant est membre de Flying
          Blue, nous pouvons inclure le numéro Flying Blue et des offres
          spéciales en rapport avec Flying Blue dans nos newsletters. Nous
          proposons Flying Blue en collaboration avec la compagnie du groupe Air
          France. Pour plus d'informations, veuillez consulter le paragraphe
          "Qui sommes-nous" de notre{" "}
          <a
            href="https://www.klm.lu/fr/information/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            politique de confidentialité
          </a>
        </li>
        <li>
          Pour comprendre ce qui est pertinent pour le Participant, nous
          utilisons des outils automatiques pour analyser les données
          personnelles. Le Participant a le droit de s'opposer à cette
          utilisation à tout moment. Pour plus d'informations sur la manière
          dont nous utilisons vos données et sur vos droits, veuillez consulter
          notre{" "}
          <a
            href="https://www.klm.lu/fr/information/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            politique de confidentialité
          </a>
        </li>
        <li>
          Si un Participant ne souhaite plus recevoir la newsletter de KLM, il
          peut se désinscrire en cliquant sur le bouton qui se trouve en bas de
          la newsletter de KLM.
        </li>
      </ul>
    </>
  );
};

export default TermsConditionsFrench;
